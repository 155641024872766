.component-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap; }
  .component-slider .slider-container {
    margin: 0 20px;
    overflow: hidden; }
  .component-slider .slider-content {
    display: inline-block;
    transition: margin-left 0.15s ease-in; }
  .component-slider .caret {
    width: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    color: #797979; }
  .component-slider .caret-right {
    right: 0; }
  .component-slider .caret-left {
    left: 0; }
